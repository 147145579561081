export const validateStandard = (value, fieldValue) => {
  let error = "";
  if (value == "" || value == undefined) {
    error = fieldValue
      ? `${fieldValue} is required.`
      : "Error.fieldValidationEmptyField";
  }
  return error;
};

export const validateEmailAddress = (value, component) => {
  let error = "";
  if (component && value === "") {
    error = "Email address is required";
  } else {
    // checks for common validation
    error = validateStandard(value);
  }

  // checks for custom validation
  const validateEmail =
    /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

  // return re.test(String(email).toLowerCase());
  if (value && value !== "" && !validateEmail.test(value.toLowerCase())) {
    error = "Incorrect email format";
  }
  return error;
};

export const validatePassword = (value) => {
  let error = "";
  if(value===undefined || value==""){
    error = validateStandard(value, "Password")
    return error
  }
  
  if (value.length > 0 && !value.match(/^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/)) {
    // error = "Error.fieldValidationPasswordLength";
    error = "Your password must be at least 8 characters long and include at least 1 uppercase, special character, and a number."
  }
  return error;
};

export const validatePhoneNumber = (value) => {
  let error = "";
  // checks for common validation
  error = validateStandard(value);
  if (value !== "" && error == "") {
    if (value && value.length !== 10) {
      error = "Error.fieldValidationPhoneDigits";
    }
    if (
      value &&
      (value.indexOf("-") > -1 ||
        value.indexOf("(") > -1 ||
        value.indexOf(")") > -1)
    ) {
      error = "Error.fieldValidationPhoneOnlyDigits";
    }
  }
  return error;
};

export const validateUserName = (value, userNameKey) => {
  let error = "";
  // Shortcut for not displayed
  error = validateStandard(value, userNameKey);
  if (value.length > 0) {
    let userName = Object.assign(value);
    if (userName.indexOf("@") == -1 && userNameKey == "phone") {
      // is not email and is phone for user name so strip () -
      while (userName.indexOf(")") > -1) {
        userName = Object.assign(userName.replace(")", ""));
      }
      while (userName.indexOf("(") > -1) {
        userName = Object.assign(userName.replace("(", ""));
      }
      while (userName.indexOf("-") > -1) {
        userName = Object.assign(userName.replace("-", ""));
      }
      while (userName.indexOf(" ") > -1) {
        userName = Object.assign(userName.replace(" ", ""));
      }
      error = validatePhoneNumber(userName, true, true);
    } else if (!(userName.indexOf("@") == -1)) {
      error = validateEmailAddress(userName);
    } else {
      error = validateStandard(value, userNameKey);
    }
  }
  return error;
};

export const getUsernameLabel = (retailerConfig, locale) => {
  let retVal = "";
  // if internationalization is present
  // Have to do it this way for legacy retailers or when they do not specify this
  if (
    Object.keys(retailerConfig).indexOf(
      "loginPlaceHolderTextInternationalization"
    ) > -1
  ) {
    retVal = retailerConfig.loginPlaceHolderTextInternationalization[locale];
  } else {
    retVal = retailerConfig.loginPlaceHolderText;
  }
  return retVal;
};

export const validateZip = (value, length) => {
  let error = "";
  // checks for common validation
  error = validateStandard(value);
  if (value !== "" && value !==undefined && error == "") {
    if (value.length !== length) {
      error = "Error.fieldValidationZipLength";
    }
  }else{
   error = validateStandard(value, 'zip')
  }
  return error;
};

export const validatePasswordTwo = (value, key, formvalues) => {
  let passwordError = "";
  if (value !== formvalues[key])
    passwordError = "Error.fieldValidationPasswordDoNotMatch";
  return passwordError;
};

export const validateConfirmPwd = (value1, value2)=>{
  let passwordError = "";
  if(value1 !==value2){
    console.log('checking pass- ', value1, value2)
    passwordError = "Error.fieldValidationPasswordDoNotMatch";
  }
  return passwordError;
}

// Error Handling based on the validationtype
export const formErrorHandling = (value, key, formValues) => {
  let error = "";
  switch (key) {
    case "email":
      error = validateEmailAddress(value);
      break;
    case "passwordOne":
      error = validatePassword(value);
      break;
    case "passwordTwo":
      error = validatePasswordTwo(value, "passwordOne", formValues);
      break;
    case "phoneNumber":
      error = validatePhoneNumber(value);
      break;
    case "zip":
      error = validateZip(value, 5);
      break;
  }
  return error;
};
