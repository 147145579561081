import React from "react";
import { useSelector } from "react-redux";
import OfferItem from "./Item";
import BulkOfferClip from "../offers/bulkOfferClip";
import "./offersV2.scss";
import { useParams } from "react-router-dom";

const OffersV2 = (props) => {
  const params = useParams();
  const siteConfig = props.page
  const offerBlock = siteConfig.components.find(component => component.block_type === 'OfferBlock');
  let isLoading = !params.id ? false : true,
    queryId = params ? params.id : false,
    deepLinkOffer = params.id ? findOffer(params.id) : "";
  let available = useSelector((state) => state.offer.collections.available);
  let featured = useSelector((state) => state.offer.collections.featured);
  let links = siteConfig.colorLinks;
  const isMobileTwoColumns = offerBlock.use_two_column_mobile;

  function findOffer (id) {
    let deepLinkOffer = available.find(
      (cpn) => cpn.mdid === parseInt(id)
    );
    isLoading = false;
    return deepLinkOffer;
  }

  function RenderDeepLink (props) {
    const { cpn } = props;
    return (
      <div>
        <div className="SingleOffer">
          <OfferItem coupon={cpn} key={cpn.mdid} page={siteConfig} />
        </div>
      </div>
    );
  }

  function RenderOfferList (props) {
    return (
      <div className="offerContent2">
        <div className="w-full max-w-[1440px] mx-auto px-4 py-8 flex flex-col justify-start items-center gap-4">
          <div className="text-center text-zinc-800 text-4xl font-extrabold font-['Switzer Variable'] leading-tight sm:text-5xl md:text-6xl lg:text-7xl">In Store Digital Coupons</div>
          <div className="w-full max-w-[680px] text-center text-zinc-800 text-base font-normal font-['Switzer Variable'] leading-snug sm:text-lg md:text-xl">Your Publix savings journey starts here – clip coupons to use in-store!</div>
          {/* <div className="w-full max-w-[680px] text-center text-zinc-800 text-base font-bold font-['Switzer Variable'] underline leading-snug sm:text-lg md:text-xl">Become a Club Publix Member today!</div> */}
        </div>
        {offerBlock && offerBlock.use_bulk_offer_clip && (
          <BulkOfferClip siteConfig={siteConfig} />
        )}
        <div className={`OfferList ${isMobileTwoColumns ? 'mobile-two-columns' : ''}`}>
          {props.available.map((cpn) => {
            return <OfferItem coupon={cpn} key={cpn.mdid} page={props.page} isMobileTwoColumns={isMobileTwoColumns} />;
          })}
        </div>
      </div>
    );
  }
  function RenderFeaturedList(props) {
    return (
      <div className="FeaturedOfferList">
        {props.featured.map((cpn) => {
          return <OfferItem coupon={cpn} key={cpn.mdid} isFeatured={true} page={props.page} />;
        })}
      </div>
    );
  }
  function trackViewMoreEvent (){
    window.gtag('event', 'View More Coupons', { 
      retailer_id: "Publix",
      version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A'
    });
  }



  if (isLoading) {
    return "";
  }

  //displays offer-section of page
  if (queryId && deepLinkOffer) {
    return <RenderDeepLink cpn={deepLinkOffer} page={siteConfig} />;
  } else if (!queryId && (available.length > 0 || featured.length > 0)) {
    return (
      <div className="offer-section" style={{ color: links, paddingBottom: "1%" }}>
        {featured.length > 0 && <RenderFeaturedList featured={featured} page={siteConfig} />}
        <RenderOfferList available={available} page={siteConfig} />
        <br></br>
        {offerBlock && offerBlock.show_view_more_coupons && (
        <a
          className="viewMore"
          href="https://www.publix.com/savings/digital-coupons"
          onClick={trackViewMoreEvent}
          style={{ color: links }}
        >
          View more Publix digital coupons...
        </a>
      )}
      </div>
    );
  } else {
    return (
      <div className="offer-section">
        <p>There are no available offers</p>
      </div>
    );
  }
};

export default OffersV2;
