import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MetaDataBlock from '../metadata';
import LoginModal from '../loginModal';
import Hero from '../hero';
import Cart from '../cart';
import Recipes from '../recipes';
import Chatbot from '../chatbot'
// import Footer from '../footer';
import Rebates from '../rebates'
import Spinner from '../common/Spinner';
import ErrorToast from '../common/ErrorToast';
import LogoGardenImages from '../logoGarden';
import TikTokSection from '../tiktok';
import InstagramSection from '../instagram';
import FreeText from '../freeText';
import ContactBlock from '../contact';
import LoyaltyBlock from '../loyalty';
import InformationBanner from '../informationBanner';
import FAQBlock from '../faq';
import DateAgeGate from '../ageGate/date';
import NumberAgeGate from '../ageGate/number';
import CheckboxAgeGate from '../ageGate/checkbox';
import OfferBlock from '../offerBlock'
import '../App.css';
import Navigation from '../navigation';
import ChatbotButton from '../chatbotButton';
import OfferCarousel from '../offerCarousel/offerCarousel';
import LoginModalV2 from '../loginModalV2/loginModalV2';
import SweepstakesEmbed from '../sweepstakesEmbed/sweepstakesEmbed';
import Footer from '../footerV2/footer';
import ProgramExplanation from '../programExplanation/programExplanation';

// takes the value of the page data passed in as a prop
function SitePage({page}) {
  // looks for an age gate component. 
  // if there is one, it will lock the site with an age gate until the user confirms their age
  const ageGate = page.components.find((component) => component.block_type === 'AgeGateBlock');
  const [ageGateLocked, setAgeGateLocked] = useState(ageGate ? true : false);
  const [isLoading, setIsLoading] = useState(false)
  
  // finding metadata block for the page
  // IMPORTANT!: BRANDSITES NEED METADATA OR THEY WILL CRASH
  const metaData = page.components.find((component) => component.block_type === 'MetaDataBlock');
  
  // only agegate, header and footer will be shown if age gate is present
  const header = page.components.find((component) => component.block_type === 'HeaderBlock');
  const footer = page.components.find((component) => component.block_type === 'FooterBlock');
  const offerBlock = page.components.find((component) => component.block_type === 'OfferBlock');
  const offerVersion = (offerBlock && offerBlock.version) || 'v1';

  const showLogin = useSelector((state) => state.offer.show_login);

  function ModalBackdrop() {
    return showLogin ? <div className="modal-backdrop fade show"></div> : '';
  }

  const appClasses = `App ${showLogin ? 'modal-open' : ''}`;

  const updateAgeGate = () => {
    setAgeGateLocked(!ageGate);
  };

  const updateLoadingState = () => {
    setIsLoading(!isLoading)
  }

  if (isLoading) {
    return <Spinner />;
  
    // IMPORTANT! If the live_date for the brandsite is set to a future date, 
    // only the header and footer will show
  } else if (new Date(page.live_date) > new Date()) {
    return (
      <>
        {metaData && <MetaDataBlock page={page}/>}
        {header && <Hero page={page}/>}
        {!header && 'Coming Soon'}
        {footer && <Footer page={page}/>}
      </>
    );

    // Logic for locked age gate
  } else if (ageGateLocked) {

    // displaying different age gate values for different input_type field values
    switch (ageGate.input_type) {
      case 'Number':
        return (
          <>
            {metaData && <MetaDataBlock page={page} />}
            {header && <Hero page={page}/>}
            <NumberAgeGate
            page={page}
              ageGateLocked={ageGateLocked}
              updateAgeGate={updateAgeGate}
              title={ageGate.title}
              instructions={ageGate.instructions}
              instructions2={ageGate.instructions_2}
              minimum_age={ageGate.minimum_age}
            />
            {footer && <Footer page={page}/>}
          </>
        );
      case 'Date':
        return (
          <>
            {metaData && <MetaDataBlock page={page} />}
            {header && <Hero page={page}/>}
            <DateAgeGate
              ageGateLocked={ageGateLocked}
              updateAgeGate={updateAgeGate}
              title={ageGate.title}
              instructions={ageGate.instructions}
              instructions2={ageGate.instructions_2}
              minimum_age={ageGate.minimum_age}
              page={page}
            />
            {footer && <Footer page={page}/>}
          </>
        );
      case 'Boolean':
        return (
          <>
            {header && <Hero page={page}/>}
            <CheckboxAgeGate
              ageGateLocked={ageGateLocked}
              updateAgeGate={updateAgeGate}
              title={ageGate.title}
              instructions={ageGate.instructions}
              instructions2={ageGate.instructions_2}
              minimum_age={ageGate.minimum_age}
              page={page}
            />
            {footer && <Footer page={page}/>}
          </>
        );
      default:
        return null;
    }
  } 


  // if there is no age gate and the site is set to be live, 
  // the other components display in order based on their order in components list from backend response
  else {
    const renderComponent = (component) => {
      switch (component.block_type) {
        case 'Rebates':
          return <Rebates page={page}/>;
        case 'ContactBlock':
          return <ContactBlock page={page}/>;
        case 'LoyaltyBlock':
          return (
            <>
            {/* loading tesseract before it is needed to avoid time delay when it is used */}
              <LoyaltyBlock page={page}/>
            </>
          );
        case 'FAQBlock':
          return <FAQBlock page={page}/>;
        case 'LogoGardenBlock':
          return <LogoGardenImages page={page}/>;
        case 'MetaDataBlock':
          return <MetaDataBlock page={page}/>;
        case 'QualifyingProductsBlock':
          return <InformationBanner page={page}/>;
        case 'TikTokBlock':
          return <TikTokSection page={page}/>;
        case 'HeaderBlock':
          return <Hero page={page}/>;
        case 'OfferBlock':
          return (
            <OfferBlock updateLoadingState={updateLoadingState} page={page}/>
          );
        case 'AddToCartBlock':
          return <Cart page={page}/>;
        case 'RecipeBlock':
          return <Recipes page={page}/>;
        case 'InstagramBlock':
          return <InstagramSection page={page}/>;
        // case 'FooterBlock':
        //   return <Footer page={page}/>;
        case 'FreeTextBlock':
          return <FreeText page={page}/>;
        case 'ChatbotBlock':
          return <Chatbot page={page}/>;
        case 'ChatbotButtonBlock':
          return <ChatbotButton page={page}/>;
        case 'OfferCarouselBlock':
          return < OfferCarousel page={page} />
        case 'SweepstakesBlock':
          return <SweepstakesEmbed page={page} />
        case 'ProgramExplanationBlock':
          return <ProgramExplanation page={page} />
        default:
          return null;
      }
    };

    return (
      <div
        className={('App ', appClasses)}
        id="App"
        style={{
          backgroundColor: page.colorBackground,
          color: page.colorText,
          fontFamily: page.font_family
        }}
      >
        {offerVersion === 'v2' ? (
          <LoginModalV2 page={page} />
        ) : (
          <LoginModal page={page} />
        )}
        <div className={`App-content container`} id="App-content">
          <div id="fb-root"></div>
          <div id="fb-customer-chat" className="fb-customerchat"></div>
          <div>
            <Navigation page={page} />
            {page.components.map((component, index) => (
              <div key={index}>{renderComponent(component)}</div>
            ))}
          </div>
          
          <ErrorToast page={page} />
        </div>
        <ModalBackdrop />
        {footer && <Footer page={page}/>}
      </div>
      
    );
  }
}

export default SitePage;
