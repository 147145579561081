import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function CPGPage({ page }) {
  const navigate = useNavigate();

  useEffect(() => {
    // If user is not log in redirect to login page.
    if (!localStorage.getItem("token")) {
      navigate(`/${page.brandsite_name}/login`);
    }
  }, []);

  return (
    <>
      <h1>Welcome to MY loyality program page</h1>
    </>
  );
}

export default CPGPage;
