import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOGIN, CLIP_OFFER } from '../redux/reducers/offer';
import { SET_USER } from '../redux/reducers/user';
import './loginModalV2.css';
import { trackClick } from '../utils/gaTrackingFunctions';

function LoginModal(props) {
  const siteConfig = props.page;
  const offerBlock = siteConfig.components.find((component) => component.block_type === 'OfferBlock');
  const dispatch = useDispatch();
  const {
    offer: { show_login: show, mdid, location, isBulkClip },
    config: {
      retailer: { id: retailerId },
    },
  } = useSelector((state) => state);


  const availableOffers = useSelector((state) => state.offer.collections.available);

  const handleClose = () => {
    setLoginError(false);
    dispatch(SHOW_LOGIN({ show: false }));
  };

  let rememberUser = useRef(false);
  let [username, setUserName] = useState();
  let [loginError, setLoginError] = useState('');
  let [isDisabled, setEnableBtn] = useState(true);
  const mpid = siteConfig.mpId;
  let funnel = siteConfig.funnel ? siteConfig.funnel : 'n/a';
  let channel = siteConfig.funnel ? siteConfig.funnel : 'n/a';
  const colorTheme = siteConfig.components.find((component) => component.block_type === 'ColorTheme');

  function handleValidation() {
    if (username) {
      const sanitizedUsername = username.replace(/\D/g, '');

      if (isBulkClip) {
        // Clip all available offers
        console.log(availableOffers);
        availableOffers.forEach((offer) => {
          dispatch(
            CLIP_OFFER({
              endpoint: 'offers/load',
              method: 'POST',
              body: {
                id: offer.mdid,
                username: sanitizedUsername,
                mediaPropertyId: mpid,
                channel: channel,
                funnel: funnel,
                location: 'bulk_offer_clip',
              },
            })
          );
        });

        dispatch(SET_USER({ state: 2, username: sanitizedUsername }));
        if (rememberUser.current.checked) {
          saveToStorage(sanitizedUsername, JSON.stringify(availableOffers[0].mdid));
        }
        trackLoginEvent();
        handleClose();
      } else {
        // Old behavior: clip a single offer
        const options = {
          endpoint: 'offers/load',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-retailer': retailerId,
            Origin: window.location.origin,
          },
          body: {
            username: sanitizedUsername,
            id: mdid,
            mediaPropertyId: mpid,
            channel: channel,
            funnel: funnel,
          },
        };

        dispatch(CLIP_OFFER(options)).then((resp) => {
          if (resp.payload.success) {
            dispatch(SET_USER({ state: 2, username: sanitizedUsername }));
            if (rememberUser.current.checked) {
              saveToStorage(sanitizedUsername, JSON.stringify(resp.payload.mdid));
            }
            trackLoginEvent();
            handleClose();
          } else {
            setLoginError(resp.payload.errorMsg);
          }
        });
      }
    } else {
      setLoginError('Member Id required');
    }
  }
  
  function saveToStorage(username, mdid) {
    const sanitizedUsername = username.replace(/\D/g, '');
    const obj = { username: sanitizedUsername, myIds: mdid };
    localStorage.setItem('pbyt', JSON.stringify(obj));
  }
  function RenderLoginError() {
    if (loginError) {
      return <div>{loginError}</div>;
    }
    return '';
  }
  function trackLoginEvent (){
    window.gtag('event', 'account_login', { 
      retailer_id: "Publix",
      version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A',
    });
  }
  if (show) {
    const primary = siteConfig.colorPrimary;
    const secondary = siteConfig.colorSecondary;
    const links = siteConfig.colorLinks;
  
    return (
      <div className={`modal fade ${show ? 'show' : ''}`} id="loginModal" style={{ display: show ? 'block' : 'none' }} role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content w-[374px] h-[405px] md:w-[500px] md:h-[550px] p-6 md:p-16 bg-white rounded-3xl shadow mx-auto border-0">
            <button
              type="button"
              className="absolute top-4 right-4 md:top-8 md:right-8 bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={handleClose}
              aria-label="Close"
            >
              <span className="sr-only">Close menu</span>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3438 10.4336C11.6602 10.7852 11.6602 11.3125 11.3438 11.6289C10.9922 11.9805 10.4648 11.9805 10.1484 11.6289L6 7.44531L1.81641 11.6289C1.46484 11.9805 0.9375 11.9805 0.621094 11.6289C0.269531 11.3125 0.269531 10.7852 0.621094 10.4336L4.80469 6.25L0.621094 2.06641C0.269531 1.71484 0.269531 1.1875 0.621094 0.871094C0.9375 0.519531 1.46484 0.519531 1.78125 0.871094L6 5.08984L10.1836 0.90625C10.5 0.554688 11.0273 0.554688 11.3438 0.90625C11.6953 1.22266 11.6953 1.75 11.3438 2.10156L7.16016 6.25L11.3438 10.4336Z" fill="#6B7280" />
              </svg>
            </button>
            <div className="flex flex-col justify-start items-center gap-4 md:gap-6">
              <div className="self-stretch h-[83px] md:h-[100px] flex-col justify-start items-center gap-2 flex">
                <div className="text-center text-zinc-800 md:text-[31px] text-[21px] font-bold md:leading-[34.10px] leading-[23px]">
                  {offerBlock.login_popup_text && offerBlock.login_popup_text.trim() !== '' ? offerBlock.login_popup_text : 'Sign In'}
                </div>
                <div className="self-stretch text-center text-gray-500 md:text-lg font-normal leading-relaxed md:leading-[28.80px]">
                  Enter the 10 digit phone number associated with your Club Publix account.
                </div>
              </div>
              <form className="self-stretch">
                <div className="mb-4">
                  <input
                    type="tel"
                    className="w-full p-4 bg-white rounded-lg border border-gray-400 text-gray-500 md:text-lg font-normal leading-relaxed md:leading-[28.80px]"
                    placeholder="555-555-5555"
                    value={username}
                    onChange={(e) => {
                      let input = e.target.value.replace(/\D/g, '');
                      input = input.slice(0, 10);
    
                      let formattedInput = '';
                      for (let i = 0; i < input.length; i++) {
                        if (i === 3 || i === 6) {
                          formattedInput += '-';
                        }
                        formattedInput += input[i];
                      }
                      setUserName(formattedInput);
                      // checkModalValidation(e);
                    }}
                    maxLength={12}
                    required
                  />
                </div>
                <div className="mb-1 flex items-center">
                  <input
                    type="checkbox"
                    className="mr-2 text-center text-gray-500 text-lg font-normal leading-[27px]"
                    id="rememberMe"
                  />
                  <label htmlFor="rememberMe" className="mb-0 text-zinc-800 text-lg font-normal leading-[28.80px]">
                    Remember me on this computer
                  </label>
                </div>
                <div className="mb-0 text-red-500 text-lg font-normal leading-none">
                  <RenderLoginError />
                </div>
              </form>
              <button
                className="w-42 mx-auto px-12 py-3 rounded-[200px] text-lg font-bold leading-[28.80px]"
                style={{
                  backgroundColor: colorTheme.primary_color,
                  color: colorTheme.primary_button_text_color,
                }}
                onClick={handleValidation}
              >
                Sign In
              </button>
              <div className="justify-start items-center gap-1 inline-flex">
                <div className="text-center text-zinc-800 md:text-lg font-normal leading-relaxed md:leading-[28.80px]">
                  Don't have a Club Publix account?
                </div>
                <div className="text-black md:text-lg font-bold underline leading-relaxed md:leading-[28.80px]">
                  <a href="https://www.publix.com/myaccount/register" target="_blank" style={{ color: 'black' }} onClick={() => trackClick('retailer_registration', 'popup')}>
                    Join now
                  </a>
                </div>
              </div>
              <div className="text-center text-gray-500 text-sm font-normal leading-snug">
                Terms & Conditions apply
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    
  }

  return '';
}
export default LoginModal;
