import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './Rebates.scss';
import CustomDropZone from './customDropZone';
import PreviewSubmission from './previewSubmission';

function SubmissionForm(page) {
  const { register, handleSubmit, formState: { errors }, reset, getValues } = useForm();
  let pageData = page.page;
  const rebatesData = pageData.components.find((component) => component.block_type === 'Rebates');

  const [selectedButton, setSelectedButton] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [paymentMethodError, setPaymentMethodError] = useState('');

  const clearForm = () => {
    console.log('clearForm');
    reset();
    setSelectedButton(null);
    setUploadedFiles([]);
  };

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
    setPaymentMethodError('');
  };

  const handleEdit = () => {
    setShowForm(true);
    setShowPreview(false);
  };

  const handleCancel = () => {
    setShowPreview(false);
    setShowForm(true);
  };

  const handleSuccess = () => {
    setShowPreview(false);
  };

  const closeForm = () => {
    clearForm();
    setShowForm(false);
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  const onSubmit = (data) => {
    setFormSubmitted(true);
    setPaymentMethodError('');
    if (uploadedFiles.length === 0) {
      console.log('Please upload a file before submitting the form');
      return;
    }
    if (selectedButton === null) {
      setPaymentMethodError('Please select a payment method');
      return;
    }
    setShowForm(false);
    setShowPreview(true);
    if (showForm) {
      const targetDiv = document.getElementById('top-of-form');
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: 'smooth' });
      }
    }
    console.log('Submitted Data:', data);
  };

  const handleSubmitReceiptClick = () => {
    setShowForm(true);

    setTimeout(() => {
      const targetDiv = document.getElementById('top-of-form');
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  const handleFileUpload = (files) => {
    setUploadedFiles(files);
  };

  const expirationDate = new Date(rebatesData.expiration_date);
  const formattedDate = (expirationDate.getMonth() + 1) + '/' + (expirationDate.getDate() + 1) + '/' + expirationDate.getFullYear();

  return (
    <>
      <div className='claim-your-rebate-header'>
        <h1 style={{ margin: '0px', marginTop: '15px' }}>CLAIM YOUR CASH BACK</h1>
      </div>
      <div className='submission-form-container' style={{ backgroundColor: rebatesData.color_background_1 }}>
        <div className='image-column-container'>
          <div className='left-column-image-container'>
            <img src={rebatesData.image} alt="Coors Light" />
          </div>
        </div>
        <div className='right-column-image-text'>
          <div className='save-header'>
            {rebatesData.header}
          </div>
          <div className='save-subheader'>
            {rebatesData.subheader}
          </div>
          <div className='expiration-date'>
            Submit receipts by {formattedDate}
          </div>
          <div id="submit-receipt-container">
            <button id="submit-receipt" onClick={handleSubmitReceiptClick}>
              SUBMIT RECEIPT
            </button>
          </div>
          <div className='right-column-disclaimer'>
            {rebatesData.terms_and_conditions}
          </div>
        </div>
      </div>
      {showForm && (
        <div className='submission-form-container' style={{ backgroundColor: rebatesData.color_background_2 }}>
          <div className='submission-form-column-right'>
            <div className='submission-form-header' id="top-of-form">
              <h2>SUBMISSION INFORMATION</h2>
            </div>
            <div className="container mt-1">
              <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3 label-left with-spacing">
                <label htmlFor="firstName" className="form-label rebates-form-label">First Name <span className='red'>*</span></label>
                <input
                  type="text"
                  className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                  id="firstName"
                  placeholder="Enter your first name"
                  {...register("firstName", {
                    required: "This field is required",
                    maxLength: {
                      value: 16,
                      message: "First name must be less than 16 characters"
                    },
                    pattern: {
                      value: /^[A-Za-z'-]+$/,
                      message: "First name must only contain letters, dashes (-), and apostrophes ('), no spaces or other special characters allowed"
                    }
                  })}
                />
                {errors.firstName && <span className="invalid-feedback">{errors.firstName.message}</span>}
              </div>
              <div className="mb-3 label-left with-spacing">
                <label htmlFor="lastName" className="form-label rebates-form-label">Last Name <span className='red'>*</span></label>
                <input
                  type="text"
                  className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                  id="lastName"
                  placeholder="Enter your last name"
                  {...register("lastName", {
                    required: "This field is required",
                    maxLength: {
                      value: 16,
                      message: "Last name must be less than 16 characters"
                    },
                    pattern: {
                      value: /^[A-Za-z'-]+$/,
                      message: "Last name must only contain letters, dashes (-), and apostrophes ('), no spaces or other special characters allowed"
                    }
                  })}
                />
                {errors.lastName && <span className="invalid-feedback">{errors.lastName.message}</span>}
              </div>
                <div className="mb-3 label-left">
                  <label htmlFor="email" className="form-label">Email <span className='red'>*</span></label>
                  <input
                    type="email"
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                    id="email"
                    placeholder="Enter your email address"
                    {...register('email', { required: true })}
                  />
                  {errors.email && <span className="invalid-feedback">Email is required</span>}
                </div>
                <div className='with-spacing'>
                  <h3 className='product-upc'>PAYMENT METHOD</h3>
                  <p className='product-upc-desc'>How would you like to receive your rebate?</p>
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <button
                          type="button"
                          className={`btn btn-primary rebates-custom-button ${selectedButton === 'PayPal' ? 'selected-button' : ''}`}
                          onClick={() => handleButtonClick('PayPal')}
                        >
                          PayPal
                        </button>
                      </div>
                      <div className="col">
                        <button
                          type="button"
                          className={`btn btn-primary rebates-custom-button ${selectedButton === 'Venmo' ? 'selected-button' : ''}`}
                          onClick={() => handleButtonClick('Venmo')}
                        >
                          Venmo
                        </button>
                      </div>
                    </div>
                  </div>
                  {paymentMethodError && (
                    <div style={{ marginTop: '5px' }}>
                      <span style={{ color: 'red', fontWeight: '700' }}>{paymentMethodError}</span>
                    </div>
                  )}
                </div>
                {selectedButton === "PayPal" && (
                  <div className="mb-3 label-left" style={{ marginTop: '10px' }}>
                    <label htmlFor="paypalemail" className="form-label" style={{ padding: '5px' }}>
                      <strong>PayPal Email <span className='red'>*</span></strong>
                    </label>
                    <input
                      type="email"
                      className={`form-control ${errors.paypalemail ? 'is-invalid' : ''}`}
                      id="paypalemail"
                      placeholder="Enter your PayPal account email address"
                      {...register('paypalemail', { required: selectedButton === 'PayPal' })}
                    />
                    {errors.paypalemail && <span className="invalid-feedback">PayPal email is required</span>}
                  </div>
                )}
                {selectedButton === "Venmo" && (
                  <div className="mb-3 label-left" style={{ marginTop: '10px' }}>
                    <label htmlFor="venmoNumber" className="form-label" style={{ padding: '5px' }}>
                      <strong>Venmo Number <span className='red'>*</span></strong>
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend phone-prepend">
                        <span className="input-group-text">+1</span>
                      </div>
                      <input
                        type="tel"
                        className={`form-control phone-input ${errors.venmoNumber ? 'is-invalid' : ''}`}
                        id="venmoNumber"
                        placeholder="Enter your Venmo phone number"
                        {...register('venmoNumber', {
                          required: selectedButton === 'Venmo',
                          minLength: 14,
                          maxLength: 14,
                        })}
                        onChange={(e) => {
                          let inputValue = e.target.value.replace(/\D/g, '');

                          if (inputValue.length > 0) {
                            inputValue = "(" + inputValue;
                          }
                          if (inputValue.length > 4) {
                            inputValue = inputValue.slice(0, 4) + ") " + inputValue.slice(4);
                          }
                          if (inputValue.length > 9) {
                            inputValue = inputValue.slice(0, 9) + "-" + inputValue.slice(9);
                          }

                          inputValue = inputValue.slice(0, 14);
                          e.target.value = inputValue;
                        }}
                      />
                    </div>
                    {errors.venmoNumber && (
                      <span className="invalid-feedback">
                        {errors.venmoNumber.type === 'required'
                          ? 'Venmo number is required'
                          : 'Please enter a valid phone number'}
                      </span>
                    )}
                  </div>
                )}
                <div className='with-spacing'>
                  <h3 className='product-upc'>RECEIPT UPLOAD</h3>
                  <CustomDropZone onFileUpload={handleFileUpload} />
                  {formSubmitted && uploadedFiles.length === 0 && (
                    <div style={{ marginTop: '5px' }}>
                      <span style={{ color: 'red', fontWeight: '700' }}>Please upload a file before submitting the form.</span>
                    </div>
                  )}
                </div>
                <div className="row col with-spacing">
                  <div className="form-check mb-3">
                    <input
                      type="checkbox"
                      className={`form-check-input ${errors.agreeToTerms ? 'is-invalid' : ''}`}
                      id="agreeToTerms"
                      {...register('agreeToTerms', { required: true })}
                    />
                    <label className="form-check-label" htmlFor="agreeToTerms">
                      I agree to the <a href="#">Terms and Conditions</a> and to receive emails that are relevant to my rebate.<span className='red'>*</span>
                    </label>
                    {/* {errors.agreeToTerms && <span className="invalid-feedback">You must agree to the terms and conditions</span>} */}
                  </div>
                </div>
                <div className='with-spacing'></div>
                <div className='button-container'>
                  <button type="submit" className="half-width" id='review-button'>REVIEW & SUBMIT</button>
                  <button type="button" className="half-width" onClick={closeForm}>CANCEL</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {showPreview && (
        <PreviewSubmission
          page={pageData}
          formData={getValues()}
          edit={handleEdit}
          uploadedFiles={uploadedFiles}
          paymentMethod={selectedButton}
          handleCancel={handleCancel}
          handleSuccess={handleSuccess}
          closePreview={closePreview}
          rebatesData={rebatesData}
          clearForm={clearForm}
          setFormSubmitted={setFormSubmitted}
        />
      )}
    </>
  );
}

export default SubmissionForm;