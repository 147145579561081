import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import SitePage from "./site";
import CPGPage from "./pages/cpg";
import { SiteConfigProvider } from "./SiteConfigContext";
import axios from "axios";
import RecipePage from "./recipes/recipe";
import BrandsiteList from "./brandsiteList";
import LoginWrapper from "./pages/login";
import SignupWrapper from "./pages/Signup";
import ForgotWrapper from "./pages/forgotPassword";
import NotFoundPage from "./404";
import site_config_json from "./site-config.json";
import "./App.css";

function SiteComponent() {
  const { siteName } = useParams();
  let apiUrl;

  // determines if the frontend will call localhost or the cloud application for its backend response
  apiUrl = process.env.REACT_APP_BACKEND;

  const brandsiteUrl = `${apiUrl}/brandsites/${siteName}`;
  const [siteConfig, setSiteConfig] = useState(null);

  useEffect(() => {
    // getting siteConfig depending on whether or not the app is in preview_mode
    const fetchSiteConfig = async () => {
      try {
        let data;
        // if the app is in preview_mode, it will get the response from the cloud backend
        if (process.env.REACT_APP_PREVIEW_MODE === "true") {
          const response = await axios.get(brandsiteUrl);
          data = response.data;
          setSiteConfig(data);
        } else {
          // if the site is not in preview mode, it will use the JSON that was created from the backend call in the build process
          // this is what sites use in production so it is not dependent on the API
          data = site_config_json; // Use imported JSON directly
          setSiteConfig(data);
        }
      } catch (error) {
        console.error("Error fetching site_config:", error);
      }
    };

    fetchSiteConfig();
  }, [brandsiteUrl]);

  if (!siteConfig) {
    return <div>Loading...</div>;
  }

  // implementing logic for A/B testing if there is a value for brandsite_b listed on the first page
  if (siteConfig[0].brandsite_b) {
    console.log("A/B testing enabled");

    // Check if AB_TEST_VARIABLE exists in localStorage
    if (!localStorage.getItem("AB_TEST_VARIABLE")) {
      // If it doesn't exist, assign 'A' or 'B' based on the probabilities
      let brandsite_a_probability = siteConfig[0].brandsite_a_probability;
      if (brandsite_a_probability === null) {
        // If brandsite_a_probability is null, default to 0.5
        brandsite_a_probability = 0.5;
      }
      const randomValue = Math.random() < brandsite_a_probability ? "A" : "B";
      localStorage.setItem("AB_TEST_VARIABLE", randomValue);
    } else {
      // If it exists, log its value
      console.log(
        "AB TEST VARIABLE:",
        localStorage.getItem("AB_TEST_VARIABLE")
      );
    }

    if (localStorage.getItem("AB_TEST_VARIABLE") === "B") {
      console.log("setting brandsite to version B");
      setSiteConfig(siteConfig[0].brandsite_b);
    }
  }

  return (
    <SiteConfigProvider config={siteConfig}>
      <Routes>
        {/* looks at siteConfig from the backend or JSON and makes a page
         with the given slug from every list entry */}

        {/* TODO: This routing will enhance later */}
        {siteConfig[0].brandsite_name !== "cpg" ? (
          <>
            {siteConfig.map((item) => (
              <React.Fragment key={item.id}>
                <Route path={item.slug} element={<SitePage page={item} />} />
                <Route
                  path={`${item.slug}recipes/:slug`}
                  element={<RecipePage page={item} siteName={siteName} />}
                />
              </React.Fragment>
            ))}{" "}
          </>
        ) : (
          <>
            {siteConfig.map((item) => (
              <React.Fragment key={item.id}>
                <Route path={item.slug} element={<CPGPage page={item} />} />
                <Route
                  path={`${item.slug}login`}
                  element={<LoginWrapper page={item} siteName={siteName} />}
                />
                 <Route
                  path={`${item.slug}signup`}
                  element={<SignupWrapper page={item} siteName={siteName} />}
                  />
                <Route
                  path={`${item.slug}forgot`}
                  element={<ForgotWrapper page={item} siteName={siteName} />}
                />
              </React.Fragment>
            ))}
          </>
        )}
      </Routes>
    </SiteConfigProvider>
  );
}

function App() {
  let apiUrl;
  apiUrl = process.env.REACT_APP_BACKEND;

  if (process.env.REACT_APP_PREVIEW_MODE === "true") {
    // every site can be accessed from its siteName as a route path in preview mode.
    // This can be viewed from a list in CloudFront
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<BrandsiteList apiUrl={apiUrl} />} />
            <Route path="/:siteName/*" element={<SiteComponent />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  } else {
    // out of preview mode, only one brandsite can be viewed at a time and all of its routes begin with / instead of /:siteName
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<SiteComponent />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
